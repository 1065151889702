<template>
    <footer class="bg-lo-pink-darkest text-white pt-12 pb-16">
        <div class="container mx-auto flex ">
            
            <div class="mx-auto w-2/4 flex">
                <div class="w-3/4 text-lo-pink-lighter pr-12">
                    «Sammen om endring» er utviklet av LO med støtte fra Kompetansepolitisk råd. <br>
                    <a href="https://gjerholm.no/" target="_blank">Utviklet og designet i samarbeid med Gjerholm</a>
                </div>
                <div class="w-1/4 flex justify-end">
                    <div class="text-left">
                        <a
                            @click="openLink({ path: '/artikkel/383'})"
                            class="text-white cursor-pointer block"
                            :target="$route.name == 'veileder' ? '_blank' : '_self'">
                            Veileder
                        </a>
                        
                        <a
                            @click="openLink({ path: '/artikkel/32'})"
                            class="text-white cursor-pointer block"
                            :target="$route.name == 'veileder' ? '_blank' : '_self'">
                            Om verktøyet
                        </a>
                        
                        <a
                            class="text-white"
                            href="https://docs.google.com/forms/d/14xRkrYGN7NRilOkJ8BrJNkajg5yeKQ1ARaUKco7WbYk/edit?usp=sharing"
                            target="_blank">
                            Hjelp og kontakt
                        </a>
                    </div>
                </div>
                
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        data(){
            return {
            
            }
        }
    }
</script>


<style lang="scss" scoped>
    
</style>
